
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');

:root {
  --panelBgColor: #EFF2FB;
  --textcolor: #6a6a92;
  --textBlackColor: #333;
  --textPrimaryColor: #2323ce;
  --textSubPrimaryColor: #1285f5;
  --textPurpleColor: #701fb4;
  --whiteColor: #fff;
  --main-bg-color: brown;
  --font-Assistant: 'Assistant', sans-serif;
  --font-roboto-black: 'Roboto', sans-serif;
  --font-roboto-regular: 'Roboto', sans-serif;
}



@font-face {
  font-family: 'Assistant';
  src: url(../src/Assests/Fonts/Assistant-VariableFont_wght.ttf);
}
@font-face {
  font-family: 'Roboto-Black';
  src: url(../src/Assests/Fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url(../src/Assests/Fonts/Roboto-Regular.ttf);
}


*{
  font-family: var(--font-Assistant);
  /* margin: 0;
padding: 0; */
img{
  width: 100%;
  height: auto;
}
}
/* body{
  background-color: var(--panelBgColor);
} */
.ReactModal__Overlay{
  background-color: rgb(46 44 44 / 75%) !important;
}
.ReactModal__Content{
  padding: 2.5vw !important; 
  inset: 2.2vw !important;
}
.MBHAMBURGER{
  display: none;
}
.MobileView{
  display: none !important;
}
.desktopView{
  display: block;
}
.HR_whitesection{
  background-color: var(--whiteColor);
  padding: 1vw 5vw;
}
.HR_panel{
  background-color: var(--panelBgColor);
  padding: 1vw 5vw;
}
button{
  cursor: pointer;
}
.HR_primaryclr{
  color: var(--textPrimaryColor) !important;
}
.HRlistsize{
  font-size: 1.25vw;
  color: var(--textPrimaryColor) !important;
  font-weight: 700;
  font-family: var(--font-Assistant);
  margin: 10px 0 0 0 ;
  line-height: 1.5;
}
.HR_Grediant{
  background: linear-gradient(135deg, var(--textSubPrimaryColor), var(--textPrimaryColor));
}
.HR_textclr{
  color: var(--textcolor) !important;
}
.circle {
  width: 250px;
  height: 400px;
  background-color: var(--textcolor); 
  border-radius: 50%; 
  position: absolute;
  z-index: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* // Custom CSS */
/* .HR_MainPage{
  background-color: var(--panelBgColor);
  padding: 1vw 5vw;
} */
.Hr_flex{
  display: flex;
  justify-content: center;
  
}
.HR_width span{
  font-size: 1vw !important;
  font-family: var(--font-Assistant);
  color: var(--textcolor);
}
.Hr_flex span{
  font-size: 1.5vw;
  font-family: var(--font-Assistant);
  color: var(--textcolor);
}

.HR_navMenu ul{
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3vw;
}
.HR_navMenu ul li a{
 cursor: pointer;
 color: var(--textBlackColor);
 font-size: 1.3vw;
 text-decoration: none;
 font-family: var(--font-Assistant);
 font-weight: 600;

}

.HR_navMenu ul li a:hover{
  color: var(--textPrimaryColor);
}
.HR_MainHeaderSection{
  width: auto;
  /* margin-bottom: 2vw; */
}
.HR_FlexCenterSpcBetween{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HR_DemoButtons{
  display: flex;
  align-items: center;
  gap: 1vw;
}
.HR_DefaultButton button{
  background: #fff;
  color: var(--textPrimaryColor);
  border-color: var(--textPrimaryColor);
  padding: 0.65vw 1vw;
  font-size: 1.2vw;
  font-weight: 400;
  border-radius: 0.2vw;
  border: 0.1vw solid var(--textPrimaryColor);
  font-family: var(--font-Assistant);
  font-weight: 600;
}

.HR_PrimaryButton button{
  background: linear-gradient(135deg, var(--textSubPrimaryColor) , var(--textPrimaryColor));
  border-color: var(--textPrimaryColor);
  padding: 0.7vw 1vw;
  font-size: 1.2vw;
  font-weight: 400;
  color: var(--whiteColor);
  border: none;
  border-radius: 0.2vw;
  font-family: var(--font-Assistant);
  font-weight: 600;
}

.HR_LogoSection img{
  width: 11vw;
  cursor: pointer;
}
.close{
  padding: 10px;
  font-size: 2.5vw !important;
  color: #333 !important;
  position: absolute;
  right: 1vw;
  top: -1.1vw;
  cursor: pointer;
}

.HR_Section1LeftSide{
  max-width: 55%;
  width: 100%;
  text-align: left;

}
.secimg{
  width: 80%;
  height: auto;
}
.HR_Section1LeftSide1{
  max-width: 50%;
  width: 100%;
  text-align: left;
}
.HR_Section1LeftSide h1{
  font-family: var(--font-roboto-black);
  font-weight: 900;
  font-size: 3vw;
  color: var(--textBlackColor);
}
.HR_Section1LeftSide h3{
  font-family: var(--font-Assistant);
  font-weight: 700;
  margin: 0;
  font-size: 1.6vw;
  color: var(--textBlackColor);
}
.HR_Section1LeftSide p{
  font-size: 1.2vw;
  margin: 1vw 0vw;
}

.HR_Section1RightSide{
  width: 45%;
}
HR_Section1RightSide1{
  width: 50% !important;
}
.HR_Section1RightSide img{
  width: 100%;
}
.HR_ParentSection1{
  gap: 2vw;
  padding: 2vw 0vw;
}
.HR_MainButton{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.HR_mainHomePage{
  margin-top: 4vw;
}
.HR_HomePagesection{
  margin-bottom: 1vw;
  text-align: left;
}
.HR_HomePagesection label a{
  color: var(--textPrimaryColor);
  text-align: left;
  font-weight: 600;
  font-size: 2vw;
  text-decoration: none;
}
.HR_HomePagesection p{
  color: var(--textBlackColor);
  font-size: 1.4vw;
  margin: .8vw 0 2vw 0;

}
.HR_OuterDemoButtons{
  margin-top: 2vw;
}
.headerbutton{
  display: flex;
  gap: 5px;
}
.HR_MT{
  margin-top: 3vw;
  margin-bottom: 1.5vw;
}
.Hr_align{
  text-align: left;
  max-width: 62%;
  width: 100%;
}
.HR_width{
  max-width: 50%;
  width: 100%;
}
.HR_list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slider-container {
  position: relative;
  width: 100%; 
  overflow: hidden;
  margin: 0 auto;
  padding: 10px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  width: 100%; /* Each slide takes the full width of the container */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.active {
  /* opacity: 1;
   */
   display: block;
}
.inactive{
  display: none;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
}

.prev {
  left: 1px;
}

.next {
  right: 1px;
}
.plans-container {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
}

.plan {
  border: 1px solid var(--panelBgColor);
  border-radius: 0.54vw;
  text-align: center;
  background-color: var(--panelBgColor);
  position: relative;
  min-width: 23%;
  /* gap: 5px; */
}
.features {
  list-style: none;
  padding-bottom: 4vw;
  padding-left: 2vw;
  text-align: left;
  font-size: 1vw;
  font-family: var(--font-Assistant);
}
.text{
  font-size: 0.90vw;
  font-family: var(--font-Assistant);
}
.Ptext{
  font-size: 1.25vw;
  /* color: var(--textBlackColor); */
  font-family: var(--font-Assistant);
  margin-top: 1vw;
  margin-bottom: 1vw;

}
.subscribe-button {
  background: linear-gradient(135deg, var(--textSubPrimaryColor), var(--textPrimaryColor));
  color: #fff;
  padding: 0.8vw 1.3vw;
  border-radius: 0.54vw;
  font-family: var(--font-Assistant);
  font-size: 1vw;
  cursor: pointer;
  position: absolute;
  bottom: 1.5vw;
  border: none;

}
.blue-button{
  background-color: #fff;
  color: var(--textPrimaryColor);
  padding: 0.8vw 1.3vw;
  border-radius: 0.54vw;
  font-family: var(--font-Assistant);
  font-size: 1vw;
  cursor: pointer;
  position: absolute;
  bottom: 1.5vw;
  border: none;

}
.hrButton{
  display: flex;
  justify-content: center;
}
.HR_planprice{
  /* color: black; */
  font-weight: 650;
  font-size: 2vw;
  font-family: var(--font-Assistant);
}
.sup{
  /* color: black; */
  font-weight: 650;
  font-size: 1vw;
  font-family: var(--font-Assistant);
  vertical-align: super;
}
.tick-icon {
  margin-right: 5px; 
  font-size: 1.1vw;
}
.Hr_Link{
  font-size: 1.5vw;
  font-family: var(--font-Assistant);
  color: var(--textPrimaryColor);
  margin-left: 5px;
}

.accordion-item {
  border-bottom: 0.5px solid var(--textcolor);
  /* margin-bottom: 10px; */
}
.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  user-select: none;
}
.accordion-header h3{
margin: 0;
font-size: 1.5vw;
color: var(--textPrimaryColor);
font-weight: 500;
text-align: left;
}
.HR_faq{
 text-align: left;
 color: var(--textPrimaryColor);
 font-size: 2vw;
}
.arrow {
   color: var(--textPrimaryColor);
   font-size: 2vw;
   transition: opacity 0.3s ease, visibility 0.3s ease;
}
.accordion-content {
  padding: 10px;
  text-align: left;
  color: var(--textcolor);
  font-size: 1.3vw;
}
.HRfooter{
  display: flex;
  color: var(--whiteColor);
  padding-right: 10px;
}
.HRfooter a {
  text-decoration: none;
  margin-right: 4px;
}
.footermain{
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 20px;
}
.footermain a{
  color: var(--whiteColor);
  
}
body{
  margin: 0;
}
* {
  box-sizing: border-box;
  font-family: var(--font-Assistant);
  line-height: 1.5;
  /* / margin: 0; / */
}

.bg-image {
  position: absolute;
  bottom: -88%;
}

.container {
  /* display: flex;
  flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  height: auto;
  background-color: #242628;
  color: whitesmoke;
  padding: 3vw 5vw;
  /* background-image: url(./assets/world.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 70px;
  background-origin: padding-box; */
}
/* .logo{
  width: 20%;
} */
/* .logohelpdesk{
  width: 10%;
  margin-top: -20px;
} */
.mainlogo{
  width: 20vw;
}
.mainlogo1{
  width: 14vw;
}
.menu{
  border: 0.2vw solid var(--whiteColor);
  padding: 1.25vw;
  border-radius: 0.54vw;
  display: flex;
  line-height: 1;
  justify-content: center;
  height: 4vw;
  align-items: center;
}
.menu a{
  text-decoration: none;
  font-size: 1.5vw;
  line-height: 1;
  color: var(--panelBgColor);
}
.header{
  display: flex;
  justify-content: space-between;
 
}
.aboutmain{

 margin-bottom: 20px;
}
.aboutmain1{
  background-color: black;
  color: white; 
  padding: 3vw 5vw;

}
.aboutmain h3{
  text-align: left;
  font-size: 2.5vw;
  margin-bottom: 0;


}
.aboutmain h2{
  text-align: center;
  font-size: 3vw;
  margin: 0;

  

}
.aboutmain p{
  text-align: left;
  font-size: 1.5vw;
  padding-top: 1vw;
  margin-top: 0;
  margin-bottom: 2.5vw;
}
.noteptag{
  text-align: left !important;
  font-size: 1.3vw !important;
  padding-top: 1vw !important;
  margin-top: 0 !important;
  margin-bottom: 2.5vw !important;
}
.aboutmain li{
  text-align: left;
  font-size: 1.5vw;
  padding-top: 1vw;

}
.aboutmain a{
  display: table;
  font-size: 1.5vw;
  padding-top: 1vw;
  color: white;


}
.aboutmain h4{
  text-align: left;
  font-size: 1.5vw;
  margin-bottom: 0;

}
.about h3{
  text-align: center;
  font-size: 2.5vw;
  font-weight: bold;
  /* padding: 1.5vw; */
  padding-bottom: 0;
  margin: 0;
}
.HDtext{
  color: #a71af4 !important;
  margin-left: 1.4vw;
}
.about a{
  text-align: left;
  font-size: 1.5vw;
  padding-top: 1vw;
  color: white;

}
.about p{
  text-align: justify;
  font-size: 1.5vw;
  line-height: 1.5;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.HR_heading{
  font-family: var(--font-roboto-black), sans-serif;
  font-weight: 900;
  font-size: 2.5vw;
  color: var(--textPrimaryColor);
}
.footerimg{
  width: 50px;
  height: auto;
}

.blue-plan{
  background: linear-gradient(135deg, var(--textSubPrimaryColor)24%, var(--textPrimaryColor)86%);
  color: #ffffff !important;
}
.svgstyle{
  font-size: 2vw;
  margin-right: 10px !important;
}
.MBHB{
  /* display: none; */
  opacity: 0;
}

.HR_DialogLabel{
  font-size: 1.5vw;
}
.ms-TextField-fieldGroup, .ms-TextField-fieldGroup:hover, .ms-Dropdown-title, .ms-Dropdown:hover{
  border-color: #d3d1ce !important;
  border-width: .1vw;
  border-radius: 0;
}
.ms-TextField-fieldGroup::after, .ms-Dropdown::after{
  border-width: .1vw;
  border-radius: 0;
}
.MuiPaper-root{
  background-color: #f5f5f5 !important;
  min-width: 20vw;
  max-height: calc(100% - 15px) !important;
  
}
.GetStartedModal .MuiPaper-root{
  background-color: #f5f5f5 !important;
  min-width: 20vw;
  max-width: 24vw !important;
  max-height: calc(100% - 15px) !important;
}
.ms-Dialog-actionsRight{
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiButtonBase-root{
  text-transform: unset;
}
.MuiDialogContent-root{
  padding: 1.2vw 1.3vw !important;
}
.ms-TextField-wrapper label, .ms-Dropdown-container label{
  font-size: 1vw;
  padding: 0.2vw 0px 0.1vw 0;

}
.ms-TextField-wrapper input{
  font-size: 1vw;
  padding: 0px 28px 0px 0.3vw;
}
.ms-Dropdown{
  font-size: 1vw;
}
.ms-TextField-fieldGroup{

  height: 2.4vw;
}
.ms-Dropdown-title{
  height: 2.4vw;
  padding: 0px 28px 0px 0.5vw;
 line-height: 2.1vw;
}

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 2s linear forwards infinite;
  animation: fadeinout 2s linear forwards infinite;
  opacity: 0;
}

.elementToFadeInAndOut div {
  width: 6px;
  height: 6px;
   background: #fff;
  /* background: var(--lightdarkColor); */
  display: inline-block;
  margin-right: 2px;
  border-radius: 50%;
}

.elementToFadeInAndOutCont {
  -webkit-animation: fadeinout 2s linear forwards infinite;
  animation: fadeinout 2s linear forwards infinite;
  opacity: 0;
}

.elementToFadeInAndOutCont div {
  width: 6px;
  height: 6px;
  background: #000;
  display: inline-block;
  margin-right: 2px;
  border-radius: 50%;
}

@-webkit-keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
}
.SuccessColor{
  color:green;
}
.green-tick-icon{
  color:green;
  margin-right: 5px;
}
.redmarkMessag{
  color: #d34444;
}
.blackcolor{
  color: #333;
}
.CrossIcon{
  font-size: 1.4vw;
  cursor: pointer;
  margin-left: 0.5vw;
}
.ErrorColor{
  color: #d34444;
}
.mbmenu{
  list-style: none;
  padding-left: 5vw;
}
.HR_LogoSection a{
  text-align: left;
}
.HR_logowithTExt{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.HR_logowithTExt img{
 width: 6vw;
}
.HR_logoaboutus img{
  width: 14vw;
 }
 .HR_logoaboutus label{
  cursor: pointer;
  font-size: 2.5vw;
  font-family: var(--font-roboto-black);
  color: var(--textPurpleColor)
 }
.HR_logowithTExt label{
  cursor: pointer;
  font-size: 2.5vw;
  font-family: var(--font-roboto-black);
  color: var(--textPurpleColor)
 }





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Actual About US */
.MainFeaturebox{
  display: grid;
  grid-template-columns: calc(50% - 1vw) calc(50% - 1vw);
  padding: 0vw 5vw;
  margin-top: 3vw;
  gap: 2vw 2vw;
}

.Featurebox{
  display: flex;
  align-items: self-start;
  background: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  padding: 2vw 2vw;
  box-shadow: 0px 0px 3px #ddd;
}
.FeatureContent{
  width: calc(100% - 7vw);
  margin-left: 2vw;
}
.FeatureContent label{
  text-align: left;
  font-size: 1.5vw;
  font-weight: 700;
  font-family: var(--font-Assistant);
}
.FeatureContent p{
  text-align: justify;
  color: var(--textcolor)
}

.FeatureSection{
  background: #ebe5ff;
  background-image: url('https://www.cubiclogics.com/wp-content/uploads/2023/10/dashedLinesNeutral.png');
  padding-bottom: 4vw;
}
.FeatureHeading{
  padding: 4vw 5vw 0 5vw;
  font-family: var(--font-roboto-black);
  font-weight: 900;
  font-size: 3vw;
  color: var(--textPrimaryColor);
  margin: 0;
  text-align: left;
}

.MainWorkbox{
  display: grid;
  grid-template-columns: calc(33% - 1vw) calc(33% - 1vw) calc(33% - 1vw);
  padding: 0vw 5vw;
  margin-top: 3vw;
  gap: 2vw 2vw;
}
.SubWorkbox{
  display: grid;
  grid-template-columns: calc(50% - 1vw) calc(50% - 1vw);
  padding: 0vw 5vw;
  margin-top: 5vw;
  gap: 2vw 2vw;
}

.Workbox{
  background: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  padding: 2vw 2vw;
  box-shadow: 0px 0px 3px #ddd;
}
.WorkContent label{
  text-align: left;
  font-size: 1.5vw;
  font-weight: 700;
  font-family: var(--font-Assistant);
}
.WorkContent p{
  text-align: justify;
  color: var(--textcolor)

}

.WorkSection{
  background: #EFF2FB;
  /* background-image: url('https://www.cubiclogics.com/wp-content/uploads/2023/10/dashedLinesNeutral.png'); */
  padding-bottom: 4vw;
}
.WorkHeading{
  padding: 4vw 5vw 0 5vw;
  font-family: var(--font-roboto-black);
  font-weight: 900;
  font-size: 3vw;
  color: var(--textPrimaryColor);
  margin: 0;
  text-align: left;
}
.FeatureImage{
  margin-top: 4.5vw;
}
.WorkImage, .FeatureImage{
  background: #EFF2FB;
  border: 1px solid transparent;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
.WorkImage svg, .FeatureImage svg{
  color: var(--textPrimaryColor);
  height: 2.4vw;
  width: 2.4vw;
}

@media only screen and (max-width: 768px){
  .MainFeaturebox,  .MainWorkbox, .SubWorkbox{
    grid-template-columns: 100%;
    gap: 5vw 2vw;
  }
  .Featurebox{
    display: block;
    padding: 5vw 5vw;
  }
  .Workbox{
    padding: 5vw 5vw;
  }

  .WorkImage, .FeatureImage{
    width: 15vw;
    height: 15vw;
    margin: auto;
  }
  .FeatureImage svg,.WorkImage svg{
    width: 9vw;
    height: 9vw;
  }
  .WorkContent label, .FeatureContent label{
    text-align: center;
    font-size: 5vw;
  }
  .FeatureHeading, .WorkHeading{
    font-size: 7vw;
    text-align: center;
  }
  .logo {
    width: 100%;
}
.mainlogo1{
  width: 35vw;
}
.mainlogo{
  width: 40vw;
}
.container{
    background-size: contain;
    background-position-y: center;
    }
  pre {
    font-size: 0.8rem;
    padding: 8px 12px;
    width: 100%;
  }

  body {
    width: 100vw;
  }

  .header img {
    margin-left: 0;
  }

  .installation-steps {
    width: 100%;
    box-sizing: border-box;
  }

  h1 {
    font-size: 1.6rem;
  }

  ol {
    padding-left: 20px;
  }
  .about h3{
    font-size: 6vw;
  }
  .about p{
    font-size: 3.5vw;
  }
  .about a{
    font-size: 3.5vw !important;
  }
  .HDtext{
    margin-left: 2vw;
  }
  .aboutmain h3{
    font-size: 6vw;
  }
  .aboutmain p{
    font-size: 3.5vw;
  }
  .aboutmain a{
    font-size: 3.5vw;
  }
  .aboutmain li{
    font-size: 3.5vw;
  }
  .menu{
    height: 9vw;
    margin-top: 2vw;
    line-height: 1.5;
  }
  .menu a {
    font-size: 3.5vw;
  }
  .aboutmain h2{
    font-size: 6vw;
  }
  .HR_logowithTExt img {
    width: 25vw !important;
}
.MobileView{
  display: block !important;
}
.desktopView{
  display: none;
}
.HR_logowithTExt label{
  font-size: 9.5vw
 }
  .MBHB{
    /* display: block; */
    opacity: 1;
    transition: opacity 0.6s linear;
    /* transition: all 0.3s linear; */
  }
  .HR_Section1LeftSide1{
    max-width: 100%;
    width: 100%;
    text-align: left;
  }
  .HR_LogoSection{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }
  .MBHAMBURGER{
    display: none;
    font-size: 40px;
    margin: 12px 0px;
    /* padding: 20px; */
    color: var(--textPurpleColor);

  }
  .cross{
    font-size: 40px;
    /* padding: 20px; */
    color: var(--textPrimaryColor);
    font-weight: 600;
  }

 .mbmenu a{
  text-decoration: none !important;
  }
  .mbmenu li{
    text-align: left;
    font-size: 8vw;
  }
   .mbflex{
    display: flex;
    justify-content: space-between;
    padding: 0 5vw;
   }
   .mbflex li{
    text-decoration: none;
   }
  .MB_heading{
    font-size: 5.5vw !important;
    font-family: var(--font-Assistant);
  }
  .mbHRfooter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    background-size: contain;
    width: 100%;
    height: auto;
  }
  .MB_pera{
    font-size: 4.5vw !important;
    padding-bottom: 10px;
  }
  .footermain{
    display: block;
  }
  .HRfooter{
    font-size: 3.8vw;
  }
  .svgstyle{
    font-size: 5vw;
    padding: 10px;
  }
  .MB_button{
    display: none;
  }
  .HR_LogoSection img{
    /* max-width: 90%; */
    width: 70%;
  }
  .HR_navMenu{
    display: none;
  }
  .HR_FlexCenterSpcBetween{
    display: block;
  }
  .HR_Section1LeftSide{
    max-width: unset;
  }
  .HR_Section1LeftSide h1{
    font-size: 8vw;
  }
  .HR_Section1LeftSide p{
    font-size: 4vw;
  }
  .HR_Section1LeftSide h3{
    font-size: 5vw;
    font-family: var(--font-Assistant);
    text-align: center;
    font-weight: 600;
  }
  .HR_OuterDemoButtons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
  .HR_DemoButtonsSection{
    padding: 10px;
  }
  .HR_PrimaryButton button{
    font-size: 4vw;
    border-radius: 1.2vw;
    padding: 2vw 3vw;
  }
  .HR_DefaultButton button{
    font-size: 4vw;
    border-radius: 1.2vw;
    padding: 2vw 3vw;
  }
 .HR_Section1RightSide{
  padding-top: 10px;
  width: 100%;
 }
 .HR_heading{
  font-size: 6vw;
 }
 .HR_list .Hr_flex{
  display: block;
 }
 .HR_width{
  max-width: unset;
  text-align: center;
 }
 .HR_width span{
  font-size: 4.5vw !important;
 }
 .Hr_flex span{
  font-size: 4.5vw !important;
 }
 .HRlistsize{
  font-size: 5vw;
 }
 .Mb_grid{
  display: grid;
 }
 .Hr_Link{
  text-align: center;
  font-size: 4.5vw;
 }
 .Mb_grid span{
  font-size: 4.5vw;
 }
 .slide{
  background-size: contain;
  width: 100%;
  height: 24vh;
 }
 .HR_faq{
  font-size: 5vw;
 }
 .accordion-header h3{
  font-size: 4vw;
  margin-top: unset;
 }
 .accordion-content{
  font-size: 3.5vw;
 }
 .arrow{
  font-size: 5vw;
  transition: opacity 0.3s ease, visibility 0.3s ease;
 }
 .plans-container{
  display: block;
 }
 .plan{
  margin-bottom: 1vw;
  border-radius: 3.54vw;
  margin: 15px;
 }
 .HR_planprice{
  font-size: 5vw;
 }
 .text{
  font-size: 2.7vw;
 }
 .Ptext{
  font-size: 3.5vw;
 }
 .features{
  font-size: 4vw;
  padding-left: 5vw;
  padding-bottom: 12vw;
 }
 .tick-icon{
  font-size: 3.2vw;
 }
 .sup{
  font-size: 3vw;
 }
.subscribe-button{
  font-size: 4vw;
  border-radius: 1.54vw;
  padding: 1.8vw 2.3vw;
  margin-bottom: 8px;
}
.blue-button{
  font-size: 4vw;
  border-radius: 1.54vw;
  padding: 1.8vw 2.3vw;
  margin-bottom: 8px;
}
.Hr_align{
  max-width: unset;
}

.GetStartedModal .MuiPaper-root {
  min-width: 70% !important;
  max-width: 80% !important;
}
.MuiPaper-root{
  min-width: 70% !important;
  max-height: calc(100% - 15px) !important;
}
.HR_DialogLabel{
  font-size: 5.5vw;
}
.CrossIcon{
  font-size: 5.5vw;
}
.ms-TextField-wrapper label, .ms-Dropdown-container label{
  font-size: 4vw;
}
.ms-TextField-wrapper input{
  font-size: 4vw;
  padding: 0px 2vw 0px 2.3vw;
}
.ms-TextField-fieldGroup {
height: 9.4vw;
}
.ms-Dropdown{
  font-size: 4vw;
}
.ms-Dropdown-title {
  height:  9.4vw;
  padding: 0px 2vw 0px 2.3vw;
  line-height: 9.4vw;
}
.MuiDialogContent-root {
  padding: 3.2vw 3.3vw !important;
}
  }