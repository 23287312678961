@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --panelBgColor: #EFF2FB;
  --textcolor: #6a6a92;
  --textBlackColor: #333;
  --textPrimaryColor: #2323ce;
  --textSubPrimaryColor: #1285f5;
  --textPurpleColor: #701fb4;
  --whiteColor: #fff;
  --main-bg-color: brown;
  --font-Assistant: 'Assistant', sans-serif;
  --font-roboto-black: 'Roboto', sans-serif;
  --font-roboto-regular: 'Roboto', sans-serif;
}
@font-face {
  font-family: 'Assistant';
  src: url(../../src/Assests/Fonts/Assistant-VariableFont_wght.ttf);
}
@font-face {
  font-family: 'Roboto-Black';
  src: url(../../src/Assests/Fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url(../../src/Assests/Fonts/Roboto-Regular.ttf);
}


*{
  font-family: var(--font-Assistant);
  /* margin: 0;
padding: 0; */
img{
  width: 100%;
  height: auto;
}
}
.mainpanel{
    background-color: #f8faff;
    padding: 2vw 6vw;
}
.Leftcontainer{
    width: 50%;
}
.Rightcontainer{
    width: 50%;
}
.Flex{
    display: flex;
}
.mainheading{
    font-family: "montserrat";
    font-weight: 800;
    color: #2323ce;
    font-size: 3vw;
    text-align: left;
}
.mainheading2{
    font-family: "montserrat";
    font-weight: 500;
    color: #2323ce;
    font-size: 2.7vw;
}
.secondContainer{
    font-family: var(--font-roboto-black);
    font-size: 2.9vw;
    font-weight: 900;
    text-align: left;
    color: #2323ce;
}
.pera{
    font-family: var(--font-Assistant);
    font-size: 1.25vw;
    text-align: left;
    color: #6a6a92;
}
li {
    list-style: none;
    padding-left: 30px; 
  }
  
li::before {
    content: "\2713"; /* Unicode for the tick mark */
    display: inline-block;
    width: 20px; /* Adjust the width and height as needed */
    text-align: center;
    margin-left: -30px; /* Negative margin to overlap with the text */
  }
  .subheading{
    text-align: center;
    font-size: 3.5vw;
    font-weight: 800;
    color: #2323ce;
  }
  .subpera{
    text-align: center;
    font-size: 1.5vw;
    color: var(--textcolor);
  }
  .days{
    color: #2323ce;
  }
  .cardborder{
    border: 1px solid #333;
    border-radius: 0.54vw;
  }
  .cardwidth{
    width: 30%;
  }
  .imgsize{
    width: 7vw;
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
  }
  
  .card {
    width: calc(33.33% - 1vw); 
    margin-bottom: 2vw; 
  }
  
  .first-row {
    width: calc(33.33% - 1vw);
  }
  .description{
    text-align: left;
    font-size: 1.25vw;
  }
  .description1{
    text-align: left;
    font-size: 1.25vw;
    color: #fff;
    margin: 0;
  }
  .Availailty{
        width: calc(50% - 10px); /* Adjust width and margins as needed */
        margin-bottom: 20px; /* Adjust margin as needed */
        border-radius: 0.54vw;
        background-color: #ffffff ;
        border: 1px solid #f5f5f5;
  }
  .imgsizeA{
    width: 4vw;
    align-items: center;
  }
  .microsoftlogo{
    display: flex;
    align-items: center;
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: 2.5vw;
    color: #2323ce;

    border-radius: 100%;
   
  }
  .microsoftlogo1{
    display: flex;
    align-items: center;
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: 2.5vw;
    color: #ffff;

    border-radius: 100%;
   
  }
  .heading{
    font-size: 2vw;
    text-align: left;
  }
  .heading1{
    font-size: 2vw;
    text-align: left;
    color: #fff;
    margin: 0;
  }
  .heading2{
    font-size: 3vw;
    text-align: center;
    color: #fff;
    margin-bottom: 1vw;
  }
  .Availailty2{
    width: 100%;
        margin-bottom: 20px; /* Adjust margin as needed */
        border-radius: 0.54vw;
        background-color: #ffffff ;
        border: 1px solid #f5f5f5;
        margin-right: 10px;
        padding: 2vw;
  }
  .card-container1 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    background: linear-gradient(to Top, #1285f5, #2323ce);
    border-radius: 1.5vw;
    padding: 2vw;
  }
  .Availailty3{
    width: calc(50% - 10px); /* Adjust width and margins as needed */
    margin-bottom: 20px; /* Adjust margin as needed */
}
.Availailty4{
    width: 100%;
    margin-bottom: 20px; 
}

